
import './App.scss';
import { Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Portfolio from './components/Portfolio';
import Emina from './components/Emina';

import './i18n.js'

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element = {<Home />}/>
          <Route path='/about' element={<About  />} />
          <Route path='/portfolio' element={<Portfolio  />} />
          <Route path='/contact' element={<Contact  />} />
          <Route path='/emina' element={<Emina  />} />
          </Route>
        
      </Routes>
    </>
  );
}

export default App;
